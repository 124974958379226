var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{ref:"menu-notification",attrs:{"left":"","close-on-content-click":_vm.closeOnContentClick,"min-width":"350px","max-width":"350px","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{staticClass:"mr-3",attrs:{"content":_vm.notificationUnread && (_vm.notificationUnread > 999 ? '999+' : _vm.notificationUnread),"value":_vm.notificationUnread,"color":"red","overlap":""}},[_c('v-icon',_vm._g(_vm._b({staticClass:"grey--text text--lighten-1"},'v-icon',attrs,false),on),[_vm._v(" mdi-bell ")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-list',{staticClass:"overflow-auto",class:_vm.notificationLoading || !_vm.notifications.length ? 'd-flex align-center' : null,attrs:{"min-height":"150","max-height":"350"}},[(_vm.notificationLoading)?_c('v-list-item',{staticClass:"justify-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):(!_vm.notifications.length && !_vm.isErrorGetNotifications)?_c('v-list-item',{staticClass:"justify-center"},[_c('p',{staticClass:"text-center"},[_vm._v("Tidak ada pesan")])]):_c('section',[(_vm.isErrorGetNotifications)?_c('v-list-item',{staticClass:"justify-center d-flex flex-column pt-8"},[_c('h3',[_vm._v(_vm._s(_vm.$_strings.common.ERROR_MESSAGE))]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-large":"","icon":""},on:{"click":function($event){return _vm.fetchNotificationList(_vm.notificationPage, 'notificationLoading')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-reload ")])],1)]}}],null,false,2768827058)},[_c('span',[_vm._v("Reload")])])],1):_c('div',_vm._l((_vm.notifications),function(notification,index){return _c('v-list-item',{key:index},[_c('v-card',{staticClass:"mb-2 w-100",class:!notification.notificationData.isRead ? 'grey lighten-3' : '',attrs:{"hover":"","light":""},on:{"click":function($event){return _vm.notificationClicked(notification)}}},[_c('v-card-title',[_c('span',{staticClass:"caption text-subtitle-2 font-weight-black"},[_vm._v(" "+_vm._s(notification.notificationData.title)+" ")])]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.perseRelativeTime(notification.date))+" ")]),_c('v-card-text',{staticClass:"caption"},[_vm._v(" "+_vm._s(notification.notificationData.body)+" ")])],1)],1)}),1),((_vm.notifications.length < _vm.totalNotifications)
          && !_vm.notificationLoadingLoadMore
          && !_vm.isErrorGetNotifications
        )?_c('v-list-item-action',{staticClass:"d-flex justify-end mr-4 caption"},[_c('v-btn',{staticClass:"caption",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.fetchNotificationList(_vm.notificationPage, 'notificationLoadingLoadMore')}}},[_vm._v(" Muat lebih banyak.. ")])],1):_vm._e(),(_vm.notificationLoadingLoadMore)?_c('v-list-item',{staticClass:"justify-center"},[_c('v-progress-circular',{attrs:{"size":20,"indeterminate":"","color":"primary"}})],1):_vm._e(),_c('v-btn',{staticStyle:{"margin":"0 16px","width":"calc(100% - 32px)"},on:{"click":function($event){return _vm.readAllNotification()}}},[_vm._v(" Baca Semua Notifikasi ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }